import React from 'react';
import { LoginGigyaInterface } from '../shared/interfaces/LoginGigyaInterface';

interface GlobalContextInterface {
   fontSize?: { get:number, set:(size: number) => void },
   superFooter?: { get: boolean, set: (value: boolean) => void  },
   gigyaContext: LoginGigyaInterface 
}

const context: GlobalContextInterface = {
   gigyaContext: { login:()=>null, logout:()=>null, updateProfile:()=>null, register:()=>null }
};

const GlobalContext = React.createContext(context);

export default GlobalContext;

import React, { useState } from "react";
import Button from "../../atoms/button/button";
import "./buttonAuth.scss";
import GlobalContext from "../../../contexts/globalContext";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";


interface BtnAuthInterface {
   text:string;
   href:string;
   validateAuth:boolean;
   className: string;
   gtmMark?:string;
   strictCss?: boolean;
   id?: string;
}

const ButtonAuth = (props: BtnAuthInterface) => {

   const { text, href, validateAuth, gtmMark, className, strictCss } = props;

   const [isBtnClicked, setIsBtnClicked] = useState(false);
   const { window } = useBrowserMode();

   const goToForm = () => {
      setIsBtnClicked(false);
      setTimeout(() => {
         window.open(href, '_blank');
      }, 1000);
   }

   return (
      <GlobalContext.Consumer> 
         { data=>(
            <>
               { !validateAuth ? 
                  <Button
                  type="button"
                  text= { text }
                  classname= { className }
                  onClickAction={ 
                     () => setTimeout(() => {
                        window.open(href, '_blank');
                     }, 300)
                  }
                  target="_blank"
                  isDisabled={false}
                  gtmMark = {gtmMark}
                  strictCss = {strictCss}
                  id={props.id ? props.id : gtmMark}
               />
               :
               data.gigyaContext.gigyaAccount ? (
                  isBtnClicked ? 
                        goToForm()
                     :(
                        <Button
                           type="link"
                           text= { text }
                           classname= { className }
                           href= { href }
                           target="_blank"
                           isDisabled={false}
                           gtmMark = {gtmMark}
                           strictCss = {strictCss}
                           id={props.id ? props.id : gtmMark}
                        />
                     )
               ):(
                  <Button
                     type="button"
                     text= { text }
                     classname= { className }
                     onClickAction={ 
                        ()=>{
                           setIsBtnClicked(true); 
                           data.gigyaContext.login();
                     }}
                     target="_blank"
                     isDisabled={false}
                     gtmMark = {gtmMark}
                     strictCss = {strictCss}
                     id={props.id ? props.id : gtmMark}
                  />
               )}
            </>
         )}
      </GlobalContext.Consumer>
   );
};

export default ButtonAuth;

import { useState, useEffect } from "react";
import useBrowserMode from "./useBrowserMode";

const EVENTS = {
   WHEEL: 'wheel',
   MOUSE_WHEEL: 'mousewheel',
   DOM_MOUSE_SCROLL: 'DOMMouseScroll',
   TOUCH_END: 'touchend',
   TOUCH_START: 'touchstart',
   TOUCH_MOVE: 'touchmove'
}
/* 
   return last direction of mouse scroll
      1 -> scroll down
      -1 -> scroll up
      0 -> no scroll yet
*/
const useDirectionScroll = () => {
   /* Zone logic scroll */
   // const [supportsWheel, setSupportsWheel] = useState<boolean>(false);
   // let lastScrollTop = 0;
   const [lastDirection, setLastDirection] = useState<number>(0);
   const { isBrowser, document, window } = useBrowserMode();

   /* Esta logica funciona solo en desktop */
   const scrollDirection = (e: any) => {
      setLastDirection(window.pageYOffset < 50 ? 0 : ((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1);
      // console.log('direccion', e,((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1 );
   }

   
   /* Esta logica funciona solo en mobile 
   let timerScroll: any;
   const scrollDirection2 = () => {
      // console.log('aqui pasé', e);
      window.clearTimeout(timerScroll);
      timerScroll = setTimeout(() => {
         const st = window.pageYOffset || document.documentElement.scrollTop;
         if (st > lastScrollTop) {
            // -> Indica cuando baja console.log('bajé', lastScrollTop, st);
            setLastDirection(1);
         } else {
            // -> Indica cuando sube console.log('subí', lastScrollTop, st);
            setLastDirection(-1);
         }
         lastScrollTop = st; // For Mobile or negative scrolling
      }, 300)
   } */

   let xDown = 0;
   let yDown = 0;

   const getTouches = (evt) => {
      return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
   }

   const handleTouchStart = (evt) => {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
   };

   const handleTouchMove = (evt) => {
      if (!xDown || !yDown) {
         return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
         if (xDiff > 0) {
            /* left swipe */
         } else {
            /* right swipe */
         }
      } else {
         if (yDiff > 0) {
            /* up swipe - bajar elemento */
            setLastDirection(window.pageYOffset < 50 ? -1 : 1);
         } else {
            /* down swipe - subir elemento */
            setLastDirection(-1);
         }
      }
      /* reset values */
      xDown = 0;
      yDown = 0;
   };

   useEffect(() => {
      if (isBrowser) {
         /* Add the event listeners for each event. */
         document.addEventListener(EVENTS.WHEEL, scrollDirection);
         document.addEventListener(EVENTS.MOUSE_WHEEL, scrollDirection);
         document.addEventListener(EVENTS.DOM_MOUSE_SCROLL, scrollDirection);
         // document.addEventListener(EVENTS.TOUCH_END, scrollDirection2);
         document.addEventListener(EVENTS.TOUCH_START, handleTouchStart, false);
         document.addEventListener(EVENTS.TOUCH_MOVE, handleTouchMove, false);
      }
      return () => {
         if (isBrowser) {
            document.removeEventListener(EVENTS.WHEEL, scrollDirection);
            document.removeEventListener(EVENTS.MOUSE_WHEEL, scrollDirection);
            document.removeEventListener(EVENTS.DOM_MOUSE_SCROLL, scrollDirection);
            // document.removeEventListener(EVENTS.TOUCH_END, scrollDirection2);
            document.removeEventListener(EVENTS.TOUCH_START, handleTouchStart);
            document.removeEventListener(EVENTS.TOUCH_MOVE, handleTouchMove);
         }
      }
   }, []);
   
   const resetDirection = () => setLastDirection(-1);

   return { lastDirection, resetDirection };
   /* Close zone logic scroll  */
}

export default useDirectionScroll;

import React from 'react';
import './youtubePlayer.scss';

interface YoutubePlayerProps {
  youtubeId: string;
  id?: string;
  titulo?: string;
}

function YoutubePlayer(props: YoutubePlayerProps) {

  return (
    <iframe
      id={props.id ? props.id : ""}
      className="m-youtube__player"
      frameBorder="0"
      allowFullScreen={true}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,div,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span#play,span#circle{margin-left:3px;height:1.5em;text-align:center;font:30px/1.5 sans-serif;color:#FF277E;text-shadow:0 0 0.5em black}span#circle{margin-left:0px;font:50px/1.5 sans-serif;}div{background-color:rgba(0,0,0,0.7);height:100%;position:absolute;top:0;left:0;}</style><a href=https://www.youtube.com/embed/${props.youtubeId}?autoplay=1><img src=https://img.youtube.com/vi/${props.youtubeId}/hqdefault.jpg alt='${props.titulo}'><div></div><span id="play">▶</span><span id="circle">◯</span></a>`}
    />)
}

export default YoutubePlayer;

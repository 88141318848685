import { GigyaAccount } from "../interfaces/GigyaAccount";
import { useState, useEffect, useMemo } from "react";
import useBrowserMode from "./useBrowserMode";
import { LoginGigyaInterface } from "../interfaces/LoginGigyaInterface";
import { sendLoginEvent } from "../gtm/user.datalayer";

declare var gigya: any;

const REASON = {
  CANCELED: "canceled",
};

const useLoginGigya = (): LoginGigyaInterface => {
  const [gigyaAccount, setGigyaAccount] = useState<GigyaAccount>();
  const { isBrowser, document } = useBrowserMode();
  const [isGigya, setisGigya] = useState(false);
  const [isLoadingGigya, setIsLoadingGigya] = useState<boolean>(true);
  let interval: any = null;

  const showScreen = (startScreen: string, screenSet: string) => {
    if (isBrowser) {
      const querySelector = document.querySelector("body");
      if (querySelector?.classList.contains("freeze-page")) {
        querySelector?.classList.add("freeze-gigya");
      }
    }
    gigya.accounts.showScreenSet({
      screenSet: screenSet,
      startScreen: startScreen,
      onHide: getUSerInfo,
      deviceType: "auto",
    });
  };

  const getUSerInfo = (response: any) => {
    if (isBrowser) {
      const querySelector = document.querySelector("body");
      querySelector?.classList.remove("freeze-gigya");
    }
    if (REASON.CANCELED !== response?.reason) {
      gigya.accounts.getAccountInfo({ callback: getData });
    } else {
      console.log("REASON =>", response?.reason);
    }
  };

  const getData = (res: GigyaAccount) => {
    setIsLoadingGigya(false);
    if (res.data && res.data.userVerification) {
      setGigyaAccount(res);
      if (res.data.UID !== window.sessionStorage.getItem("cfmUserUID")) {
         window.sessionStorage.setItem("cfmUserUID", res.data.UID)
         sendLoginEvent()
      }
    } else {
      setGigyaAccount(undefined);
    }
  };

  const getLogout = (response: any) => {
    if (response.errorCode == 0) {
      gigya.accounts.getAccountInfo({ callback: getData });
    } else {
      alert("Error :" + response.errorMessage);
    }
  };

  const login = () => {
    showScreen("gigya-login-screen", "POC-FlujoComfamaID");
  };

  const loginComfamaId = () => {
    showScreen("gigya-login-screen", "POC-FlujoComfamaID");
  };

  const updateProfile = () => {
    showScreen("gigya-my-account-screen", "Comfama-ProfileUpdate");
  };

  const register = () => {
    showScreen("gigya-register-screen", "POC-FlujoComfamaID");
  };

  const logout = () => {
    gigya.accounts.logout({ callback: getLogout });
  };

  const updateProfilePhoto = async (photoBytes: string) => {
    gigya.accounts.setProfilePhoto({
      photoBytes: photoBytes,
      publish: 'true',
      callback: refreshGigyaSessionData
    });
  }

  const refreshGigyaSessionData = () => {
    gigya.accounts.getAccountInfo({ callback: getData });
  }

  useMemo(() => {
    if (isGigya) {
      gigya.accounts.getAccountInfo({ callback: getData });
      clearInterval(interval);
    }
  }, [isGigya]);

  useEffect(() => {
    interval = setInterval(() => {
      const isGigya = typeof gigya === "object"
      setisGigya(isGigya);
      if (isGigya) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return { login, loginComfamaId, register, logout, gigyaAccount, updateProfile, showScreen, updateProfilePhoto, isLoadingGigya };
};

export default useLoginGigya;

import React from "react"
import "./breadcrumb.scss"
import useBrowserMode from '../../../shared/hooks/useBrowserMode'

const Breadcrumb = (props: BreadcrumbInterface) => {

   const { content } = props;
   const { isBrowser, window } = useBrowserMode();

   const getPathName = ( index:number ) : string => {
      if(isBrowser) {
         if(index === 0) {
            return '/';
         } else {
            const paths = window.location.pathname.split('/');
            return paths[index] ? getFinalRoot(index, paths) : '#' //`/${paths[index]}/` : '#';
         }
      }
      return '';
   }

   const getFinalRoot = (n:number, paths:string[]) => {
      let pathFinal = '/';
      for (let i = 0; i < n; i++) {
         pathFinal += paths[i+1]+'/';
      }
      return pathFinal;
   }

   const breadcrumbs = (crumbs:string) => (
      crumbs.split('/').map( (value, index) => {
         return (
            <li key={index} itemProp="url" className={`crumb url-${index}${props.specialEvent ? ' --special-event' : ''}`}>
               <a title={value}
                  href={getPathName(index)}
                  rel="nofollow noreferrer"
               >
                  <span itemProp="title"> {value} </span>
               </a>
            </li>
         )}
      )
   );

	return (
      <nav itemType="https://schema.org/Breadcrumb" className='miga-de-pan'>
         <ul className={`a-breadcrumb${props.specialEvent ? ' a-breadcrumb--special-event' : ''}`}>
            {isBrowser && breadcrumbs( content )}
         </ul>
      </nav>
	)
}

interface BreadcrumbInterface {
   content: any;
   specialEvent?: boolean;
}

export default Breadcrumb

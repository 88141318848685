const outsideClick = (ref, action) => {
    function handleClickOutside(event) {
       if (ref.current && !ref.current.contains(event.target)) {
          action();
       }
    }
    if (typeof document !== `undefined`) {document.addEventListener("mousedown", handleClickOutside);}
    return () => {
       if (typeof document !== `undefined`) {document.removeEventListener("mousedown", handleClickOutside);}
    };
}

export default outsideClick

import React, { ReactElement, useEffect } from "react";
import { createPortal } from "react-dom";

import "./modal.scss";

interface ModalInterface {
  children: ReactElement;
  open: boolean;
  onClose?: Function;
  className?: string;
}

const Modal = (props: ModalInterface) => {
  const { children, onClose, open, className } = props;

  useEffect(() => {
    open
      ? document.body.classList.add("a-modal--open")
      : document.body.classList.remove("a-modal--open");
  }, [open]);

  return (
    <React.Fragment>
      {open &&
        createPortal(
          <div className="a-modal" role="dialog">
            <div
              className={`a-modal__wrapper ${className}`}
              onClick={(e) => {
                e.currentTarget === e.target && onClose();
              }}
            >
              {children}
            </div>
          </div>,
          document.body
        )}
    </React.Fragment>
  );
};

export default Modal;

declare var dataLayer: any;

const userData = () => {
    if (!dataLayer) return;
    if (window.sessionStorage.getItem('cfmUserUID')) {
        dataLayer.push({
            event: 'cfm_send_user_data',
            cfmUserUID: window.sessionStorage.getItem('cfmUserUID') || null,
        });
    }
};

const clearUserData = () => {
    window.sessionStorage.removeItem('cfmUserUID');
};

const sendLoginEvent = () => {
    if (!dataLayer) return;
    dataLayer?.push({
        event: 'cfm_user_logged_in',
        cfmUserUID: window.sessionStorage.getItem('cfmUserUID') || null,
    });
};

export { userData, clearUserData, sendLoginEvent };
